<template>
    <div :class="classes">
        <slot />
    </div>
</template>

<script>
export default {
    data() {
        return {
            baseClasses: "",
        };
    },
    computed: {
        classes() {
            return `accordion ${this.baseClasses}`;
        },
    },
};
</script>
